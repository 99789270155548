export default [
  {
    name: "date",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    field: (i: any) => (i.date ? new Date(Date.parse(i.date)) : undefined),
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "description",
    required: true,
    label: "Description",
    align: "left",
    sortable: true,
    field: (i: any) => i.description
  }
];
