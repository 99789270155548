var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-card-section',{staticClass:"title text-h6"},[_vm._v("Add/Edit Indicators")]),_c('q-separator'),_c('model-table',{ref:"table",attrs:{"flat":"","provider":_vm.provider,"crud":_vm.$service.crud.indicators,"selection":'multiple',"getName":function (i) { return i.description; },"columns":_vm.columns,"viewPermission":function (r) { return true; },"deletePermission":function (r) { return true; },"allowExpand":true,"hide-top":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"row"},[(_vm.programmeType !== 'Regranting')?_c('q-btn',{attrs:{"color":"green-5","label":"Add Indicator","icon":"add","no-caps":""},on:{"click":function($event){_vm.selected = { id: 'new' };
            _vm.editDialog = true;}}}):_vm._e(),(_vm.programmeType === 'Regranting')?_c('q-btn',{attrs:{"disable":_vm.indicators.length < 1,"color":"green-5","label":"Select Indicators","icon":"add","no-caps":""},on:{"click":function($event){_vm.selected = { id: _vm.programmeId };
            _vm.selectDialog = true;}}}):_vm._e()],1),_c('div',{staticClass:"row q-pt-sm"},[(_vm.programmeType !== 'Regranting')?_c('q-btn',{attrs:{"icon":"download","label":"Download Indicators","flat":"","no-caps":""},on:{"click":function($event){_vm.$runAsync('downloading' + _vm.id, function () { return _vm.download(); })}}}):_vm._e(),(_vm.programmeType !== 'Regranting')?_c('q-btn',{attrs:{"icon":"upload","label":"Upload Indicators","flat":"","no-caps":""},on:{"click":function($event){_vm.importDialog = true;
            _vm.isUploadRF = false;}}}):_vm._e(),_c('import-dialog',{attrs:{"uploadFunction":function (file) { return _vm.upload(file).then(function () { return _vm.$refs.table.refresh(); }); }},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}}),_c('q-btn',{attrs:{"icon":"download","label":"Download RF Template","flat":"","no-caps":"","loading":_vm.$proxy['downloading' + _vm.id]},on:{"click":function($event){_vm.rfDialog = true;
            _vm.controllerName = 'Indicators';}}}),_c('q-btn',{attrs:{"icon":"download","label":"Download RF Values","flat":"","no-caps":"","loading":_vm.$proxy['downloading' + _vm.id]},on:{"click":function($event){_vm.hasValues = true;
            _vm.rfDialog = true;
            _vm.controllerName = 'Indicators';}}}),_c('q-btn',{attrs:{"icon":"upload","label":"Upload RF","flat":"","no-caps":""},on:{"click":function($event){_vm.rfUpload = true;
            _vm.isUploadRF = true;}}})],1)]},proxy:true},{key:"row-actions",fn:function(ref){
            var scope = ref.scope;
return [(_vm.programmeType !== 'Regranting')?_c('q-btn',{attrs:{"flat":"","icon":"edit"},on:{"click":function($event){_vm.selected = scope.row;
          _vm.editDialog = true;}}}):_vm._e()]}},{key:"expanded",fn:function(ref){
          var scope = ref.scope;
return [_c('indicator-value-table',{attrs:{"programmeId":_vm.programmeId,"id":scope.row.id,"item":scope.row,"programmeType":_vm.programmeType}})]}}])}),_c('q-dialog',{model:{value:(_vm.rfUpload),callback:function ($$v) {_vm.rfUpload=$$v},expression:"rfUpload"}},[_c('indicator-upload-form',{attrs:{"programmeId":_vm.programmeId,"projectId":_vm.projectId},on:{"save":function($event){_vm.rfUpload = false}}})],1),_c('q-dialog',{model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[(_vm.selected)?_c('indicator-form',{attrs:{"id":_vm.selected.id,"inDialog":true,"projectId":_vm.projectId,"programmeId":_vm.programmeId},on:{"save":function($event){_vm.editDialog = false;
        _vm.$refs.table.refresh();},"close":function($event){_vm.editDialog = false}}}):_vm._e()],1),(_vm.programmeType === 'Regranting')?_c('q-dialog',{model:{value:(_vm.selectDialog),callback:function ($$v) {_vm.selectDialog=$$v},expression:"selectDialog"}},[_c('indicator-select-form',{attrs:{"inDialog":true,"id":_vm.programmeId,"projectId":_vm.projectId},on:{"save":function($event){_vm.selectDialog = false;
        _vm.$refs.table.refresh();},"close":function($event){_vm.selectDialog = false}}})],1):_vm._e(),_c('q-dialog',{model:{value:(_vm.rfDialog),callback:function ($$v) {_vm.rfDialog=$$v},expression:"rfDialog"}},[_c('indicator-rf-download-form',{attrs:{"controllerName":_vm.controllerName,"hasValues":_vm.hasValues,"downloadName":_vm.hasValues ? ("Result_Framework_Values_" + _vm.name) : ("Result_Framework_Template_" + _vm.name),"extractions":true,"inDialog":true,"id":_vm.programmeId,"projectId":_vm.projectId},on:{"save":function($event){_vm.rfDialog = false;
        _vm.hasValues = false;
        _vm.$refs.table.refresh();},"close":function($event){_vm.rfDialog = false;
        _vm.hasValues = false;}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }