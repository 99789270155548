

































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import DatePicker from "../DatePicker.vue";
import Loader from "../Loader.vue";
import FileInput from "@/components/FileInput.vue";
import { ActionType } from "@/data/models/ActionTypes";

@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker,
    FileInput
  }
})
export default class MeetingForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  public provider!: DataProvider<any>;
  public crud!: ICrudClient<any>;

  private item: any = null;

  created() {
    this.item = {
      date: null,
      description: "",
      meetingWith:"",
      projectId: this.projectId,
      programmeId: this.programmeId
    };
    this.provider = this.$service.providers.meetings;
    this.crud = this.$service.crud.meetings;
  }
}
