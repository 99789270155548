


































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import DataProvider from "@/lib/DataProvider";
import valueColumns from "@/data/columns/IndicatorValues";
import IndicatorValueForm from "@/components/forms/IndicatorValueForm.vue";
import InMemoryDataProvider from "@/lib/InMemory/InMemoryDataProvider";
import ICrudClient from "@/lib/ICrudClient";
@Component({
  components: {
    ModelTable,
    IndicatorValueForm
  }
})
export default class IndicatorValueTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public item!: any;

  @Prop()
  programmeType!: any;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  public provider: DataProvider<any> | null = null;
  private valueColumns = valueColumns;
  private selectedValue = null;
  private editValueDialog = false;
  private tableRows = [] as any;
  private currentItem: any = null;
  private filteredIds: any = null;

  mounted() {
    this.getTableData();
  }

  async getIndicator() {
    this.currentItem = await this.$service.providers.indicators.fetchItemAsync(
      this.id
    );
  }

  async getTableData() {
    await this.getIndicator();
    if (this.currentItem && this.currentItem.indicatorValues) {
      this.tableRows = [];
      const groups = this.currentItem.indicatorValues.groupBy(
        (x: any) => x.periodId
      );

      let index = 0;
      this.filteredIds = "";

      for (const group of groups) {
        if (index === 0) this.filteredIds += `Id ne ${group[0]}`;
        else this.filteredIds += ` and Id ne ${group[0]}`;

        const values = group[1];
        const optionGroups = values.groupBy(
          (x: any) =>
            x.breakdownOption && x.breakdownOption.resultFrameworkBreakdown.name
        );
        const crows = [];
        for (const value of optionGroups) {
          const optionValues = value[1];
          const result = {
            periodId: group[0],
            period: optionValues[0].period ? optionValues[0].period : null,
            name: optionValues[0].breakdownOption
              ? optionValues[0].breakdownOption.resultFrameworkBreakdown.name
              : "",
            values: [] as any
          };
          for (const opt of optionValues) {
            if (opt.breakdownOption) {
              const val = {
                id: opt.id,
                title: opt.breakdownOption.title,
                type: opt.breakdownOption.valueType,
                indicatorId: this.item.id,
                resultFrameWorkBreakdownValueId: opt.breakdownOption.id,
                numberValue: opt.numberValue,
                binaryValue: opt.binaryValue
              };
              result.values.push(val);
            } else {
              result.values.push(opt);
            }
          }
          crows.push(result);
        }
        if (crows.length > 0) {
          this.tableRows.push(crows);
        }
        index++;
      }
    }
    this.provider = new InMemoryDataProvider(this.tableRows);
  }

  async deleteItem(item: any) {
    const crud = this.$service.crud.indicatorValues;
    const ids = item.flatMap((x: any) => {
      { return x.values; }
    }).map((x: any) => x.id);

    await crud.deleteAsync(ids);
    this.getTableData();
  }
}
