function trunc(str: string, num: number) {
  if (!str || str.length < num) {
    return str;
  }
  return str.substr(0, num) + "...";
}

export default [
  {
    name: "Period",
    required: true,
    label: "Period Name",
    align: "left",
    sortable: false,
    field: (i: any) => i[0] && i[0].period ? i[0].period.periodName : ""
  },
  {
    name: "PeriodStart",
    required: true,
    label: "Period Start",
    align: "left",
    sortable: false,
    field: (i: any) => i[0] && i[0].period ? new Date(Date.parse(i[0].period.periodStart)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "n/a")
  },
  {
    name: "PeriodEnd",
    required: true,
    label: "Period End",
    align: "left",
    sortable: false,
    field: (i: any) => i[0] && i[0].period && i[0].period.periodEnd ? new Date(Date.parse(i[0].period.periodEnd)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "n/a")
  },
  {
    name: "Name",
    required: true,
    label: "Breakdowns",
    align: "left",
    sortable: false,
    field: (i: any) => i.map((x: any) => x.name).filter((x: any) => x).join(", ")
  }
];
