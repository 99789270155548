


































import { Currency } from "@/data/models/Currencies";
import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";

@Component({
  components: {
    FormContainer,
    Loader,
  },
})
export default class LocationForm extends Vue {
  @Prop()
  public id!: string;

  private provider: DataProvider<Currency> | null = null;
  private crud: ICrudClient<Currency> | null = null;

  private item: any = null;

  created() {
    this.provider = this.$service.providers.currencies;
    this.crud = this.$service.crud.currencies;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        value: "",
      };
    }
  }
}
