function trunc(str: string, num: number) {
  if (!str || str.length < num) {
    return str;
  }
  return str.substr(0, num) + "...";
}

export default [
  {
    name: "ProgrammeArea",
    required: true,
    label: "Programme Area",
    align: "left",
    sortable: true,
    field: (i: any) => i.programmeArea
  },
  {
    name: "Indicator",
    required: true,
    label: "Indicator",
    align: "left",
    sortable: true,
    field: (i: any) => i.name
  },
  {
    name: "Type",
    required: true,
    label: "Type",
    align: "left",
    sortable: true,
    field: (i: any) => i.type
  },
  {
    name: "UnitOfMeasurement",
    required: true,
    label: "Unit of Measurement",
    align: "left",
    sortable: true,
    field: (i: any) => i.unitOfMeasurement
  },
  {
    name: "SourceOfVerification",
    required: true,
    label: "Source of Verification",
    align: "left",
    sortable: false,
    field: (i: any) =>
      i.sourceOfVerification ? trunc(i.sourceOfVerification.value, 25) : ""
  },
  {
    name: "FrequencyOfReporting",
    required: true,
    label: "Frequency of Reporting",
    align: "left",
    sortable: false,
    field: (i: any) =>
      i.frequencyOfReporting ? trunc(i.frequencyOfReporting.value, 25) : ""
  },
  {
    name: "BaselineYear",
    required: true,
    label: "Baseline Year",
    align: "right",
    sortable: true,
    field: (i: any) => i.baselineYear
  },
  {
    name: "BaselineValue",
    required: true,
    label: "Baseline Value",
    align: "right",
    sortable: false,
    field: (i: any) => {
      if (i.notApplicable) {
        return "N/A";
      }
      if (i.tbd) {
        return "TBD";
      }
      if (i.unitOfMeasurement === "Number") {
        return i.numberBaselineValue;
      }
      if (i.unitOfMeasurement === "Scale") {
        return i.scaleBaselineValue;
      }
      if (i.unitOfMeasurement === "Average") {
        return i.averageBaselineValue;
      }
      if (i.unitOfMeasurement === "Percentage") {
        return i.percentageBaselineValue + "%";
      }
      if (i.unitOfMeasurement === "Binary") {
        return i.binaryBaselineValue ? "Yes" : "No";
      }
    }
  },
  {
    name: "TargetValue",
    required: true,
    label: "Target Value",
    align: "right",
    sortable: false,
    field: (i: any) => {
      if (i.notApplicable) {
        return "N/A";
      }
      if (i.tbd) {
        return "TBD";
      }
      if (i.unitOfMeasurement === "Number") {
        return i.numberTargetValue;
      }
      if (i.unitOfMeasurement === "Scale") {
        return i.scaleTargetValue;
      }
      if (i.unitOfMeasurement === "Average") {
        return i.averageTargetValue;
      }
      if (i.unitOfMeasurement === "Percentage") {
        return i.percentageTargetValue + "%";
      }
      if (i.unitOfMeasurement === "Binary") {
        return i.binaryTargetValue ? "Yes" : "No";
      }
    }
  }
];
