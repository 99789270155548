






























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Error from "./Error.vue";

@Component({
  components: { Error },
})
export default class Loader extends Vue {
  @Prop()
  public value!: boolean;

  @Prop()
  public uploadFunction!: (file: File) => Promise<any>;

  private file: FileList | null = null;

  private async upload() {
    if (this.file) {
      try {
        await this.uploadFunction(this.file[0]);
        this.file = null;
        this.$emit("input", false);
        this.$emit("save", false);
      } catch (e) {
        console.error(e);
        this.$q.notify({ message: e, color: "red-4" });
      }
    }
  }
}
