





























































































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import draggable from "vuedraggable";
import { Programme } from "@/data/models/Programs";
import SubArrayForm from "@/components/SubArrayForm.vue";
import { ActivityRecord } from "@/data/models/ActivityRecord";
import { Template } from "@/data/models/Templates";
import { Location } from "@/data/models/Locations";
import { TargetType } from "@/data/models/TargetTypes";
import { IndicatorType } from "@/data/models/IndicatorTypes";
import { Currency } from "@/data/models/Currencies";
import { readFileAsDataUrlAsync } from "@/lib/readFile";
import { Guid } from "guid-typescript";
import EntitySelect from "@/components/EntitySelect.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import CurrencyForm from "@/components/forms/CurrencyForm.vue";
import DonorForm from "@/components/forms/DonorForm.vue";
import DatePicker from "@/components/DatePicker.vue";
import MeetingsTable from "@/components/MeetingsTable.vue";
import ProgrammePeriodTable from "@/components/ProgrammePeriodTable.vue";
import IndicatorsTable from "@/components/IndicatorsTable.vue";
import RfFilesTable from "@/components/RFFilesTable.vue";
import { euro } from "@/data/mock/Currencies";
import FileInput from "../FileInput.vue";
@Component({
  components: {
    FormContainer,
    Loader,
    draggable,
    SubArrayForm,
    EntitySelect,
    DatePicker,
    MeetingsTable,
    IndicatorsTable,
    ProgrammePeriodTable,
    RfFilesTable,
    FileInput
  }
})
export default class ProgramsForm extends Vue {
  @Prop()
  public id!: string;

  private provider!: DataProvider<Programme>;
  private crud!: ICrudClient<Programme>;
  private tab = "data";
  private enumForm = EnumForm;
  private currencyForm = CurrencyForm;
  private donorForm = DonorForm;
  private item: Programme | null = null;
  private currentDonations: any[] = [];
  // private activities: any[] | null = null;

  created() {
    this.provider = this.$service.providers.programs;
    this.crud = this.$service.crud.programs;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        id: "00000000-0000-0000-0000-000000000000",
        title: "",
        type: null,
        locations: [],
        projects: [],
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        fundAmount: 0,
        currency: null,
        indicators: [],
        hasResultFramework: false,
        resultFramework: null,
        // activities: [],
        donations: [],
        agreement: null
      };
    }
  }

  @Watch("item")
  onItemChanged() {
    if (this.item) {
      for (const project of this.item.projects) {
        if (project.projectExtensions) {
          for (const extension of project.projectExtensions) {
            this.currentDonations.push({
              id: extension.id,
              name: project.title,
              budget: extension.budget,
              currency: extension.currency
                ? extension.currency.symbol
                : euro.symbol,
              donorName: extension.donor ? extension.donor.name : "",
              date: extension.date
            });
          }
        }
      }
    }
  }

  itemAdded(item: any) {
    if (!this.item) {
      return;
    }
    if (
      this.item.resultFramework !== null &&
      this.item.resultFramework !== undefined
    ) {
      const found = this.item.resultFramework.indicators.filter(
        (i: any) => i.id === item.id
      );
      if (!!found && found.length === 0) {
        this.item.resultFramework.indicators.push(item);
      }
    }
  }

  private async setFile(file: File, item: any, key: string) {
    const res = await readFileAsDataUrlAsync(file);
    item[key] = file.name + "|" + res;
  }

  setResultFramework(val: boolean) {
    if (this.item) {
      if (val) {
        this.item.resultFramework = {
          id: Guid.create().toString(),
          template: null,
          indicators: []
        };
      } else {
        this.item.resultFramework = null;
      }
    }
  }
}
