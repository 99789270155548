



























import { Indicator } from "@/data/models/Indicators";
import DataProvider from "@/lib/DataProvider";
import ICrudClient from "@/lib/ICrudClient";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    FormContainer,
    EntitySelect
  }
})
export default class IndicatorRFDownloadForm extends Vue {
  @Prop()
  public controllerName!: string;

  @Prop()
  public id!: any;

  @Prop()
  public projectId!: any;

  @Prop()
  public downloadName!: any;

  @Prop()
  public hasValues!: boolean;

  private item: any = [];
  private filters: any = [];

  @Watch("item")
  onChange() {
    if (this.item) {
      this.filters = {
        hasValues: this.hasValues,
        programmeId: this.id,
        projectId: this.projectId,
        periodId: this.item.map((x: any) => x.id)
      };
    }
  }

  mounted() {
    this.filters = {
      hasValues: false,
      programmeId: this.id,
      projectId: this.projectId,
      periodId: []
    };
  }
}
