







































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import DatePicker from "../DatePicker.vue";
import Loader from "../Loader.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker
  }
})
export default class ProgrammePeriodForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  public provider!: DataProvider<any>;
  public crud!: ICrudClient<any>;

  private item: any = null;

  @Watch("item.isCummulative")
  onCummulativeChange() {
    if (!this.item.isCummulative) {
      this.item.periodEnd = null;
    }
  }

  async created() {
    this.crud = this.$service.crud.programmePeriods;
    this.provider = this.$service.providers.programmesPeriods;
    if (this.id == "new") {
      this.item = {
        periodName: "",
        periodStart: null,
        periodEnd: null,
        programmeId: this.programmeId,
        isCummulative: false
      };
    } else {
      this.item = await this.provider.fetchItemAsync(this.id);
    }
  }
}
