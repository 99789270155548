

























































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import IndicatorForm from "@/components/forms/IndicatorForm.vue";
import IndicatorSelectForm from "@/components/forms/IndicatorSelectForm.vue";
import columns from "@/data/columns/Indicators";
import ImportDialog from "@/components/ImportDialog.vue";
import { saveAs } from "file-saver";
import DataProvider from "@/lib/DataProvider";
import IndicatorRfDownloadForm from "@/components/forms/IndicatorRFDownloadForm.vue";
import IndicatorValueTable from "@/components/IndicatiorValueTable.vue";
import IndicatorUploadForm from "@/components/forms/IndicatorRFUploadForm.vue"

@Component({
  components: {
    ModelTable,
    IndicatorForm,
    ImportDialog,
    IndicatorSelectForm,
    IndicatorRfDownloadForm,
    IndicatorValueTable,
    IndicatorUploadForm
  }
})
export default class IndicatorsTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public name!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  @Prop()
  public programmeType!: string;

  @Prop()
  public provider!: DataProvider<any>;

  private programmeProvider!: DataProvider<any>;
  private isUploadRF = false;
  private controllerName: any = null;
  private columns = columns;
  private editDialog = false;
  private rfDialog = false;
  private rfUpload = false;
  private selected = null;
  private importDialog = false;
  private selectDialog = false;
  private selectedValue = null;
  private indicators: any = [];
  private hasValues = false;
  private utc = new Date().toJSON().slice(0, 10);

  async mounted() {
    if (this.programmeType === "Regranting") {
      this.programmeProvider = this.$service.providers.programmeIndicators(
        this.programmeId
      );

      this.indicators = (await this.programmeProvider.fetchItemsAsync())
        .items;
    }
  }

  async download() {
    saveAs(await this.$service.downloadIndicators(this.id),
    `Indicators_${this.name}_${this.utc}.xlsx`
    );
  }

  async downloadRF() {
    saveAs(
      await this.$service.downloadIndicatorsRF(this.id),
      `Results_Framework_${this.name}_${this.utc}.xlsx`
    );
  }

  async upload(file: File) {
    if (this.isUploadRF) {
      await this.$service.uploadIndicatorsRF(file, this.id);
    } else {
      await this.$service.uploadIndicators(file, this.id);
    }
  }
}
