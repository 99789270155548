

























import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";

@Component({
  components: {
    FormContainer,
    Loader,
  },
})
export default class EnumForm extends Vue {
  @Prop()
  public id!: string;
  @Prop()
  public name!: string;
  @Prop()
  public to!: (id: string) => Location;
  @Prop()
  public provider!: DataProvider<any>;
  @Prop()
  public crud!: ICrudClient<any>;
  private item: any = null;

  async mounted() {
    if (this.id === "new") {
      this.item = {
        name: "",
      };
    }
  }
}
