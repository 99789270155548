










































































import { Component, Prop, Vue } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import columns from "@/data/columns/RFFiles";
import DataProvider from "@/lib/DataProvider";
import { saveAs } from "file-saver";
import IndicatorUploadForm from "@/components/forms/IndicatorRFUploadForm.vue";
@Component({
  components: {
    ModelTable,
    IndicatorUploadForm
  }
})
export default class RFFilesTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  @Prop()
  public provider!: DataProvider<any>;

  private rfFilesProvider: DataProvider<any> | null = null;
  private columns = columns;
  private editDialog = false;
  private selectedid = "";
  private rfUpload = false;

  async mounted() {
    if (this.provider) {
      this.rfFilesProvider = this.provider;
    } else {
      this.loadProvider();
    }
  }

  loadProvider() {
    if (this.projectId) {
      this.rfFilesProvider = this.$service.providers.getProjectRfFiles(
        this.projectId
      );
    } else {
      this.rfFilesProvider = this.$service.providers.getProgrammeRfFiles(
        this.programmeId
      );
    }
  }

  async download(name: string) {
    try {
      this.downloadBlob(await this.$service.downloadFile(name), name);
    } catch (e) {
      console.error(e);
    }
  }
  private downloadBlob(blob: Blob, name: string) {
    saveAs(
      blob,
      name
        .split("-")
        .slice(5)
        .join("-")
    );
  }

  private setApply(item: any) {
    this.$service.crud.rfFiles.saveAsync(item);
  }

  private async resetRfFiles() {
    await this.$service.resetRfFiles(this.programmeId);
    this.loadProvider();
  }
}
