

























































import { Component, Prop, Vue } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import ProgrammePeriodForm from "@/components/forms/ProgrammePeriodForm.vue";
import columns from "@/data/columns/ProgrammePeriod";
import DataProvider from "@/lib/DataProvider";
@Component({
  components: {
    ModelTable,
    ProgrammePeriodForm,
  },
})
export default class ProgrammePeriodTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  @Prop()
  public provider!: DataProvider<any>;

  private columns = columns;
  private editDialog = false;
  private selectedid = "";
}
