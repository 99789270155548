










































import { Indicator } from "@/data/models/Indicators";
import DataProvider from "@/lib/DataProvider";
import ICrudClient from "@/lib/ICrudClient";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";

@Component({
  components: {
    FormContainer,
  },
})
export default class IndicatorSelectForm extends Vue {
  @Prop()
  public id!: any;

  @Prop()
  public projectId!: any;

  private provider!: DataProvider<any>;
  private projectProvider!: DataProvider<any>;

  private item: any = null;
  private projectItems: any = null;
  private indicators: any = [];

  async created() {
    this.provider = this.$service.providers.programmeIndicators(this.id);
    this.projectProvider = this.$service.providers.projectIndicators(
      this.projectId
    );

    this.projectItems = await (
      await this.projectProvider.fetchItemsAsync()
    ).items.map((x: any) => x.name);
    this.item = await (await this.provider.fetchItemsAsync()).items;
    this.isActive();
  }

  async submit() {
    this.indicators = this.indicators.filter((x: any) => x).map((x: any) => {
      return { id: x, projectId: this.projectId };
    });
    if (this.indicators.length < 1) {
      this.indicators.push({ projectId: this.projectId })
    }
    await this.$service.crud.regrantingProgrammeIndicators.saveAsync(
      this.indicators
    );
  }

  private isActive() {
    this.indicators = this.item.map((x: any) => {
      if (this.projectItems.includes(x.name)) return x.id;
    });
  }
}
