
































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import DatePicker from "../DatePicker.vue";
import Loader from "../Loader.vue";
import FileInput from "@/components/FileInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import SubArrayForm from "@/components/SubArrayForm.vue";
import { strategic } from "@/data/mock/Activities";

@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker,
    FileInput,
    SubArrayForm,
    EntitySelect
  }
})
export default class IndicatorValueForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public indicator!: any;

  @Prop()
  public filteredIds!: any;

  @Prop()
  public programmeId!: string;

  @Prop()
  public programmeType!: string;

  @Prop()
  public rowItem!: any;

  public provider!: DataProvider<any>;
  public crud!: ICrudClient<any>;
  private item: any = [];

  private period: any = null;
  private optionType: any = "Number";

  async created() {
    this.provider = this.$service.providers.indicatorValues();
    this.crud = this.$service.crud.indicatorValues;

    if (this.id === "new") {
      this.createItem();
    } else {
      this.createItem();
      if (this.rowItem.length > 0) {
        const periods = (
          await this.$service.providers
            .programmePeriods(this.programmeId)
            .fetchItemsAsync()
        ).items;
        this.period = periods.filter(
          (x: any) => x.id === this.rowItem[0].periodId
        )[0];
        const breakdowns = this.rowItem
          .flatMap((x: any) => x.values)
          .filter((x: any) => x.resultFrameWorkBreakdownValueId != null);
        for (const value of this.item) {
          const newItem = {...value};
          for (const option of value.values) {
            if (option.resultFrameWorkBreakdownValueId) {
              const newValues = option;
              for (const breakdown of breakdowns) {
                if (option.resultFrameWorkBreakdownValueId === breakdown.resultFrameWorkBreakdownValueId) {
                  newValues.id = breakdown.id;
                  newValues.numberValue = breakdown.numberValue;
                  newValues.binaryValue = breakdown.binaryValue;
                }
              }
              newItem.values = newValues;
            }
          }
        }
      }
    }

    if (this.item && this.item[0]) {
      this.item[0].values[0].numberValue
        ? (this.optionType = "Number")
        : (this.optionType = "Boolean");
    }
  }

  createItem() {
    const noBreakdownValue = {
      name: "",
      values: [
        {
          indicatorId: this.indicator.id,
          resultFrameWorkBreakdownValueId: null,
          numberValue: null,
          binaryValue: false
        }
      ]
    };
    this.item.push(noBreakdownValue);

    if (this.indicator.resultFrameworkBreakDowns) {
      for (const breakdown of this.indicator.resultFrameworkBreakDowns) {
        const result = {
          name: breakdown.name,
          values: [] as any
        };
        for (const option of breakdown.options) {
          const value = {
            title: option.title,
            type: option.valueType,
            indicatorId: this.indicator.id,
            resultFrameWorkBreakdownValueId: option.id,
            numberValue: null,
            binaryValue: false
          };
          result.values.push(value);
        }
        this.item.push(result);
      }
    }
  }

  async submit() {
    if (!this.item) {
      return;
    }

    const values = this.item
      .flatMap((x: any) => x.values)
      .filter((v: any) => v);

    for (const value of values) {
      value.periodId = this.period.id;
    }

    if (this.item && this.item[0] && this.optionType === "Boolean") {
      this.item[0].values[0].numberValue = null;
    }

    await this.$service.crud.indicatorValues.addAsync(values);
  }

  private setLabelColor(index: any) {
    if (index % 2 === 0)
      return 'bg-teal-7 text-white';
    else
      return 'bg-orange-9 text-white'
  }
}
