















































import { Indicator } from "@/data/models/Indicators";
import DataProvider from "@/lib/DataProvider";
import ICrudClient from "@/lib/ICrudClient";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import FileInput from "@/components/FileInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import { warn } from "vue-class-component/lib/util";

@Component({
  components: {
    FormContainer,
    FileInput,
    EntitySelect,
    DatePicker
  }
})
export default class IndicatorRFUploadForm extends Vue {
  @Prop()
  public programmeId!: any;

  @Prop()
  public projectId!: any;

  private item: any = [];
  private file: File | null = null;
  private period: any = null;
  private partner: any = null;
  private inputFile: any = null;

  mounted() {
    this.item = {
      applied: false,
      programmeId: this.programmeId,
      projectId: this.projectId,
      periodId: null,
      promoterId: null,
      date: null,
      filename: null,
      base64: null
    };
  }

  @Watch("period")
  onPeriodChange() {
    this.item.periodId = this.period.id;
  }

  @Watch("partner")
  onPartnerChange() {
    this.item.promoterId = this.partner.id;
  }

  @Watch("inputFile")
  onInputFileChange() {
    this.item.filename = this.inputFile.name;
    this.item.base64 = this.inputFile.base64Contents;
  }

  async save() {
    try {
      if (this.projectId) {
        await this.$service.uploadIndicatorsRF(
          this.file,
          this.projectId,
          this.item
        );
      } else {
        await this.$service.uploadIndicatorsRF(
          this.file,
          this.programmeId,
          this.item
        );
      }
      this.$q.notify({
          message: "Item saved successfully!",
          color: "green-4",
          timeout: 2000
        });
      this.$emit('save');
    } catch (e) {
      this.$q.notify({ message: e, color: "red-4" });
    }
  }
}
