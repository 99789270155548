


























































import { Component, Prop, Vue } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import MeetingForm from "@/components/forms/MeetingForm.vue";
import columns from "@/data/columns/Meetings";
import { saveAs } from "file-saver";
import DataProvider from "@/lib/DataProvider";
@Component({
  components: {
    ModelTable,
    MeetingForm,
  },
})
export default class MeetingsTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  @Prop()
  public provider!: DataProvider<any>;

  private columns = columns;
  private editDialog = false;
  private selectedid = "";
}
