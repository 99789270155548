import { IBaseModel } from "@/data/models/IBaseModel";
import TouchSwipe from 'quasar/src/directives/TouchSwipe.js';;
import { filter } from "vue/types/umd";
import ICrudClient from "../ICrudClient";
import DataProvider from "../DataProvider";
import ItemsResult from "../ItemsResult";

export default class InMemoryDataProvider<T extends IBaseModel>
  implements DataProvider<T>, ICrudClient<T> {
  constructor(private data: T[]) {}

  public async addAsync(items: T[]): Promise<T[]> {
    this.data = this.data.concat(items);
    return this.data;
  }
  public async saveAsync(item: T): Promise<T> {
    const filtered = this.data.filter((i: any) => i.id == item.id);
    if (filtered.length === 1) {
      const idx = this.data.indexOf(filtered[0]);
      this.data[idx] = item;
      return this.data[idx];
    }
    throw new Error("Item not found");
  }
  public async deleteAsync(items: string[]): Promise<any> {
    const filtered = this.data.filter(i => items.indexOf(i.id) == -1);
    this.data = filtered;
  }

  public async fetchItemsAsync(args?: {
    top?: number;
    skip?: number;
    orderby?: string;
    descending?: boolean;
    filter?: string;
  }): Promise<ItemsResult<T>> {
    let result = this.data;
    let count = result.length;
    if (args) {
      if (args.filter) {
        result = result.filter((i: any) =>
          this.contains(i, args.filter?.toLowerCase() as string)
        );
        count = result.length;
      }
      if (args.skip) {
        result = result.slice(args.skip);
      }
      if (args.top) {
        result = result.slice(0, args.top);
      }
      if (args.orderby) {
        const i = args?.descending === true ? -1 : 1;
        result = result.sort((a: any, b: any) =>
          this.getDescendantProp(a, args.orderby as string) >
          this.getDescendantProp(b, args.orderby as string)
            ? i
            : -1 * i
        );
      }
    }

    return new ItemsResult(result, count);
  }

  getDescendantProp(obj: any, desc: string) {
    const arr: any[] = desc.split(".");
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }

  public contains(object: any, search: string): boolean {
    return Object.keys(object).some(k => {
      if (
        object[k] &&
        typeof object[k] !== "object" &&
        !Array.isArray(object[k])
      ) {
        return (
          object[k]
            .toString()
            .toLowerCase()
            .indexOf(search) >= 0
        );
      }
      if (object[k] && typeof object[k] === "object") {
        return this.contains(object[k], search);
      }
    });
  }

  public async fetchItemAsync(id: string): Promise<T> {
    const filtered = this.data.filter((i: any) => i.id == id);
    if (filtered.length === 1) {
      return filtered[0];
    }
    throw new Error("Item not found");
  }

  postAsync(path: string, data: any): Promise<any> {
    throw new Error("Method not implemented.");
  }
}
