export default [
  {
    name: "UploadedFile/Name",
    required: true,
    label: " Name",
    align: "left",
    sortable: true,
    field: (i: any) => {
      const name = i.file.name
        .split("-")
        .slice(5)
        .join("-");
      if (name.length < 42) {
        return name;
      }
      return (
        name.slice(0, 20) + ".." + name.slice(name.length - 20, name.length)
      );
    }
  },
  {
    name: "date",
    required: true,
    label: "Date",
    align: "left",
    sortable: true,
    field: (i: any) => i.date ? new Date(Date.parse(i.date)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "Project/Title",
    required: true,
    label: "Project",
    align: "left",
    sortable: true,
    field: (i: any) => (i.project ? i.project.title : "")
  },
  {
    name: "Programme/Title",
    required: true,
    label: "Title",
    align: "left",
    sortable: true,
    field: (i: any) => (i.programme ? i.programme.title : "")
  },
  {
    name: "Promoter/Name",
    required: true,
    label: "Promoter",
    align: "left",
    sortable: true,
    field: (i: any) => i.promoter ? i.promoter.name : ""
  }
];
