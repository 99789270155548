import { Currency, CurrencyPosition } from "../models/Currencies";

export const dollar: Currency = {
  id: "1",
  symbol: "$",
  value: "U.S. Dollar",
  position: 'Pre'
};

export const euro: Currency = {
  id: "2",
  symbol: "€",
  value: "Euro",
  position: 'Post'
};
export const yen: Currency = {
  id: "3",
  symbol: "¥",
  value: "Yen",
  position: 'Post'
};

export default [dollar, euro, yen];
