

















































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import DatePicker from "../DatePicker.vue";
import Loader from "../Loader.vue";
import FileInput from "@/components/FileInput.vue";
import EntitySelect from "@/components/EntitySelect.vue";
import { ActionType } from "@/data/models/ActionTypes";
import { Location } from "@/data/models/Locations";
import EnumForm from "@/components/forms/EnumForm.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker,
    FileInput,
    EntitySelect
  }
})
export default class IndicatorForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public programmeId!: string;

  public provider!: DataProvider<any>;
  public crud!: ICrudClient<any>;
  private mode = "normal";
  private item: any = null;
  private enumForm = EnumForm;

  async created() {
    this.item = {
      programmeArea: null,
      programmeId: this.programmeId,
      projectId: this.projectId,
      type: "Outcome",
      description: "",
      parentIndicatorId: null,
      parentIndicator: null,
      expectedResults: null,
      name: null,
      unitOfMeasurement: "Number",
      sourceOfVerification: null,
      sourceOfVerificationId: null,
      frequencyOfReporting: null,
      frequencyOfReportingId: null,
      resultFrameworkBreakDowns: null
    };
    this.provider = this.$service.providers.indicators;
    this.crud = this.$service.crud.indicators;
    this.item.programmeArea = (
      await this.$service.providers.programs.fetchItemAsync(this.programmeId)
    ).title;
  }

  @Watch("item")
  onItemChanged() {
    if (!this.item) {
      return;
    }
    this.mode = this.item.notApplicable
      ? "na"
      : this.item.tbd
      ? "tbd"
      : "normal";
  }
}
