export default [
    {
      name: "period name",
      required: true,
      label: "Period Name",
      align: "left",
      sortable: true,
      field: (i: any) => i.periodName
    },
    {
      name: "start date",
      required: true,
      label: "Start Date",
      align: "left",
      sortable: true,
      field: (i: any) => i.periodStart ? new Date(Date.parse(i.periodStart)) : undefined,
      format: (val: Date) => (val ? val.toLocaleDateString() : "")
    },
    {
        name: "end date",
        required: true,
        label: "End Date",
        align: "left",
        sortable: true,
        field: (i: any) => i.periodEnd ? new Date(Date.parse(i.periodStart)) : undefined,
        format: (val: Date) => (val ? val.toLocaleDateString() : "")
    }
  ];